var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"notification-card",class:{
    'new': !_vm.notification.read,
    'updating' : _vm.reading == _vm.notification.id
  },on:{"click":function($event){$event.stopPropagation();_vm.notification.text.includes('producto:') ||
            _vm.notification.text.includes('pedido:') ||
            _vm.notification.text.includes('Promocion:') ||
            _vm.notification.text.includes('Incidencia:') ||
            _vm.notification.text.includes('Incidencia cerrada:') ||
            _vm.notification.text.includes('devolución:') ||
            _vm.notification.text.includes('Promocion:') ||
            _vm.notification.text.includes('devuelto:')
            ? _vm.routeTo(_vm.notification) :
            _vm.notification.text.includes('sku:') ? _vm.routeToSku(_vm.notification) : 
            !_vm.notification.read ? _vm.markLikeReaded(_vm.notification.id) : ''}}},[_c('div',{staticClass:"card-viewed"},[_c('div',{staticClass:"card-circle-indicator rounded-circle",class:{
            'viewed': _vm.notification.read
        }})]),_c('div',{staticClass:"card-description"},[_c('div',{staticClass:"card__header"},[_c('span',{staticClass:"card-type"},[_vm._v(_vm._s(_vm.setupTitleForType(_vm.notification.type)))]),_c('span',{staticClass:"card-date"},[_vm._v(_vm._s(_vm.notification.creationDate.split('T')[0]))])]),_c('div',{staticClass:"card-summary"},[_c('p',{staticClass:"card-info"},[_c('span',[_vm._v(_vm._s(_vm.notification.text))])]),(false)?_c('div',{staticClass:"readed"},[(!_vm.notification.read)?_c('b-form-checkbox',{staticClass:"card-checkbox",attrs:{"name":"check-button","switch":"","checked":_vm.reading == _vm.notification.id,"disabled":_vm.reading != '' && _vm.reading != _vm.notification.id},on:{"change":function($event){return _vm.markLikeReaded(_vm.notification.id)}}}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }